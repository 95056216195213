import { template as template_1578809a00c44b1c8aadbae168e63336 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1578809a00c44b1c8aadbae168e63336(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
