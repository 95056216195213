import { template as template_c2595ec95d794b6e9dca6f2f68a1f6d0 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c2595ec95d794b6e9dca6f2f68a1f6d0(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
