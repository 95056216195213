import { template as template_ec82f061facb43359a5fa0bc992f61b9 } from "@ember/template-compiler";
const FKLabel = template_ec82f061facb43359a5fa0bc992f61b9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
