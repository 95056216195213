import { template as template_2dfd63ce98c8429cba7a38af41450e4a } from "@ember/template-compiler";
const FKControlMenuContainer = template_2dfd63ce98c8429cba7a38af41450e4a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
